
























import Vue from 'vue'

var _self = this;

export default {
    name: "Header",
    computed: {
        contactLink() {
            return `${this.$timberURL}/contact`;
        }
    },
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "header-toolbar", attrs: { fixed: "" } },
    [
      _c("v-toolbar-title", { staticClass: "white--text" }, [
        _c("div", { staticClass: "h1" }, [
          _c("label", [
            _c("span", { staticClass: "brandSpan" }, [
              _vm._v("timber"),
              _c("b", [_vm._v("X")])
            ])
          ])
        ])
      ]),
      _c("v-spacer"),
      _c(
        "v-layout",
        { staticStyle: { "justify-content": "flex-end" }, attrs: { row: "" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "a",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mailto-link",
                              attrs: {
                                href: "" + _vm.contactLink,
                                target: "_blank"
                              }
                            },
                            "a",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v(" mdi-email ")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Contact Us")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }